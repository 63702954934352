<template>
  <div>
    <AccordionCard :state="cards.noOpenOrDamagedTilesState">
      <template v-slot:header>
        {{ $t('noOpenOrDamagedTilesState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('noOpenOrDamagedTilesState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.noOpenOrDamagedTilesState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.noOpenOrDamagedTilesNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.noShiftedOrIncorrectlyLaidTilesState">
      <template v-slot:header>
        {{ $t('noShiftedOrIncorrectlyLaidTilesState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('noShiftedOrIncorrectlyLaidTilesState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.noShiftedOrIncorrectlyLaidTilesState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.noShiftedOrIncorrectlyLaidTilesNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.tilesProperlyCleanedState">
      <template v-slot:header>
        {{ $t('tilesProperlyCleanedState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('tilesProperlyCleanedState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.tilesProperlyCleanedState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.tilesProperlyCleanedNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.correctPanHeadScrewsUsedState">
      <template v-slot:header>
        {{ $t('correctPanHeadScrewsUsedState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('correctPanHeadScrewsUsedState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.correctPanHeadScrewsUsedState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.correctPanHeadScrewsUsedNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.minRailOverhangAfterEndClamp20mmState">
      <template v-slot:header>
        {{ $t('minRailOverhangAfterEndClamp20mmState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('minRailOverhangAfterEndClamp20mmState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.minRailOverhangAfterEndClamp20mmState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.minRailOverhangAfterEndClamp20mmNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
        <div class="flex-row">
          <div class="flex-column name">{{ $t('image_upload') }}</div>
          <div class="flex-column select">
            <AttachmentGallery
              :attachments="project.minRailOverhangAfterEndClamp20mmAttachments"
              attachmentType="minRailOverhangAfterEndClamp20mmAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
              :sortByName="false"
            ></AttachmentGallery>
            <DropzoneUpload
              class="mt-2"
              :complete="isMinRailOverhangAfterEndClamp20mmAttachmentsUploadComplete"
              :parentId="project.id"
              parentType="minRailOverhangAfterEndClamp20mmAttachments"
              :uploadCallback="onUploadProjectAttachment"
              v-on:dropzone-upload:reset="
                isMinRailOverhangAfterEndClamp20mmAttachmentsUploadComplete = false
              "
              acceptedFileTypes="image/*"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.distancesToRidgeEavesNotLessThan20cmState">
      <template v-slot:header>
        {{ $t('distancesToRidgeEavesNotLessThan20cmState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('distancesToRidgeEavesNotLessThan20cmState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.distancesToRidgeEavesNotLessThan20cmState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.distancesToRidgeEavesNotLessThan20cmNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
        <div class="flex-row">
          <div class="flex-column name">{{ $t('image_upload') }}</div>
          <div class="flex-row">
            <div class="flex-column select">
              <div>
                {{ $t('templateAttachments') }}:
                <AttachmentGallery
                  :attachments="getReportTemplateImages[93].templateAttachments"
                  :deletable="false"
                  :sortByName="false"
                  :showFilename="false"
                ></AttachmentGallery>
              </div>
            </div>
          </div>
          <div class="flex-column select">
            <AttachmentGallery
              :attachments="project.distancesToRidgeEavesNotLessThan20cmAttachments"
              attachmentType="distancesToRidgeEavesNotLessThan20cmAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
              :sortByName="false"
            ></AttachmentGallery>
            <DropzoneUpload
              class="mt-2"
              :complete="isDistancesToRidgeEavesNotLessThan20cmAttachmentsUploadComplete"
              :parentId="project.id"
              parentType="distancesToRidgeEavesNotLessThan20cmAttachments"
              :uploadCallback="onUploadProjectAttachment"
              v-on:dropzone-upload:reset="
                isDistancesToRidgeEavesNotLessThan20cmAttachmentsUploadComplete = false
              "
              acceptedFileTypes="image/*"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.moduleClampAreasObservedState">
      <template v-slot:header>
        {{ $t('moduleClampAreasObservedState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('moduleClampAreasObservedState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.moduleClampAreasObservedState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.moduleClampAreasObservedNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
        <div class="flex-row">
          <div class="flex-column name">{{ $t('image_upload') }}</div>
          <div class="flex-row">
            <div class="flex-column select">
              <div>
                {{ $t('templateAttachments') }}:
                <AttachmentGallery
                  :attachments="getReportTemplateImages[94].templateAttachments"
                  :deletable="false"
                  :sortByName="false"
                  :showFilename="false"
                ></AttachmentGallery>
              </div>
            </div>
          </div>

          <div class="flex-column select">
            <AttachmentGallery
              :attachments="project.moduleClampAreasObservedAttachments"
              attachmentType="moduleClampAreasObservedAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
              :sortByName="false"
            ></AttachmentGallery>
            <DropzoneUpload
              class="mt-2"
              :complete="isModuleClampAreasObservedAttachmentsUploadComplete"
              :parentId="project.id"
              parentType="moduleClampAreasObservedAttachments"
              :uploadCallback="onUploadProjectAttachment"
              v-on:dropzone-upload:reset="
                isModuleClampAreasObservedAttachmentsUploadComplete = false
              "
              acceptedFileTypes="image/*"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.modulesNoDamagesDirtShadingState">
      <template v-slot:header>
        {{ $t('modulesNoDamagesDirtShadingState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('modulesNoDamagesDirtShadingState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.modulesNoDamagesDirtShadingState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.modulesNoDamagesDirtShadingNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
        <div class="flex-row">
          <div class="flex-column name">{{ $t('image_upload') }}</div>
          <div class="flex-row">
            <div class="flex-column select">
              <div>
                {{ $t('templateAttachments') }}:
                <AttachmentGallery
                  :attachments="getReportTemplateImages[95].templateAttachments"
                  :deletable="false"
                  :sortByName="false"
                  :showFilename="false"
                ></AttachmentGallery>
              </div>
            </div>
          </div>
          <div class="flex-column select">
            <AttachmentGallery
              :attachments="project.modulesNoDamagesDirtShadingAttachments"
              attachmentType="modulesNoDamagesDirtShadingAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
              :sortByName="false"
            ></AttachmentGallery>
            <DropzoneUpload
              class="mt-2"
              :complete="isModulesNoDamagesDirtShadingAttachmentsUploadComplete"
              :parentId="project.id"
              parentType="modulesNoDamagesDirtShadingAttachments"
              :uploadCallback="onUploadProjectAttachment"
              v-on:dropzone-upload:reset="
                isModulesNoDamagesDirtShadingAttachmentsUploadComplete = false
              "
              acceptedFileTypes="image/*"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.moduleClampsProperlyTightenedState">
      <template v-slot:header>
        {{ $t('moduleClampsProperlyTightenedState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('moduleClampsProperlyTightenedState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.moduleClampsProperlyTightenedState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.moduleClampsProperlyTightenedNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
        <div class="flex-row">
          <div class="flex-column name">{{ $t('image_upload') }}</div>
          <div class="flex-row">
            <div class="flex-column select">
              <div>
                {{ $t('templateAttachments') }}:
                <AttachmentGallery
                  :attachments="getReportTemplateImages[96].templateAttachments"
                  :deletable="false"
                  :sortByName="false"
                  :showFilename="false"
                ></AttachmentGallery>
              </div>
            </div>
          </div>

          <div class="flex-column select">
            <AttachmentGallery
              :attachments="project.moduleClampsProperlyTightenedAttachments"
              attachmentType="moduleClampsProperlyTightenedAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
              :sortByName="false"
            ></AttachmentGallery>
            <DropzoneUpload
              class="mt-2"
              :complete="isModuleClampsProperlyTightenedAttachmentsUploadComplete"
              :parentId="project.id"
              parentType="moduleClampsProperlyTightenedAttachments"
              :uploadCallback="onUploadProjectAttachment"
              v-on:dropzone-upload:reset="
                isModuleClampsProperlyTightenedAttachmentsUploadComplete = false
              "
              acceptedFileTypes="image/*"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.thermalSeparationAfter12mModuleFieldState">
      <template v-slot:header>
        {{ $t('thermalSeparationAfter12mModuleFieldState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('thermalSeparationAfter12mModuleFieldState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.thermalSeparationAfter12mModuleFieldState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.thermalSeparationAfter12mModuleFieldNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.breakthroughProperlySealedState">
      <template v-slot:header>
        {{ $t('breakthroughProperlySealedState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('breakthroughProperlySealedState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.breakthroughProperlySealedState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.breakthroughProperlySealedNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
        <div class="flex-row">
          <div class="flex-column name">{{ $t('image_upload') }}</div>
          <div class="flex-row">
            <div class="flex-column select">
              <div>
                {{ $t('templateAttachments') }}:
                <AttachmentGallery
                  :attachments="getReportTemplateImages[97].templateAttachments"
                  :deletable="false"
                  :sortByName="false"
                  :showFilename="false"
                ></AttachmentGallery>
              </div>
            </div>
          </div>
          <div class="flex-column select">
            <AttachmentGallery
              :attachments="project.breakthroughProperlySealedAttachments"
              attachmentType="breakthroughProperlySealedAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
              :sortByName="false"
            ></AttachmentGallery>
            <DropzoneUpload
              class="mt-2"
              :complete="isBreakthroughProperlySealedAttachmentsUploadComplete"
              :parentId="project.id"
              parentType="breakthroughProperlySealedAttachments"
              :uploadCallback="onUploadProjectAttachment"
              v-on:dropzone-upload:reset="
                isBreakthroughProperlySealedAttachmentsUploadComplete = false
              "
              acceptedFileTypes="image/*"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.distanceLightningProtectionToConductivePartsLessThan05mState">
      <template v-slot:header>
        {{ $t('distanceLightningProtectionToConductivePartsLessThan05mState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('distanceLightningProtectionToConductivePartsLessThan05mState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="
              project.qualityReport.distanceLightningProtectionToConductivePartsLessThan05mState
            "
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="
                project.qualityReport.distanceLightningProtectionToConductivePartsLessThan05mNote
              "
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.gutterUndamagedState">
      <template v-slot:header>
        {{ $t('gutterUndamagedState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('gutterUndamagedState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.gutterUndamagedState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.gutterUndamagedNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.notBuiltOverRoofOverhangState">
      <template v-slot:header>
        {{ $t('notBuiltOverRoofOverhangState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('notBuiltOverRoofOverhangState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.notBuiltOverRoofOverhangState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.notBuiltOverRoofOverhangNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.cableProperlyLaidInChimneyState">
      <template v-slot:header>
        {{ $t('cableProperlyLaidInChimneyState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('cableProperlyLaidInChimneyState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.cableProperlyLaidInChimneyState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.cableProperlyLaidInChimneyNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
        <div class="flex-row">
          <div class="flex-column name">{{ $t('image_upload') }}</div>
          <div class="flex-row">
            <div class="flex-column select">
              <div>
                {{ $t('templateAttachments') }}:
                <AttachmentGallery
                  :attachments="getReportTemplateImages[98].templateAttachments"
                  :deletable="false"
                  :sortByName="false"
                  :showFilename="false"
                ></AttachmentGallery>
              </div>
            </div>
          </div>
          <div class="flex-column select">
            <AttachmentGallery
              :attachments="project.cableProperlyLaidInChimneyAttachments"
              attachmentType="cableProperlyLaidInChimneyAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
              :sortByName="false"
            ></AttachmentGallery>
            <DropzoneUpload
              class="mt-2"
              :complete="isCableProperlyLaidInChimneyAttachmentsUploadComplete"
              :parentId="project.id"
              parentType="cableProperlyLaidInChimneyAttachments"
              :uploadCallback="onUploadProjectAttachment"
              v-on:dropzone-upload:reset="
                isCableProperlyLaidInChimneyAttachmentsUploadComplete = false
              "
              acceptedFileTypes="image/*"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.distanceToDisturbingSurfaces10cmObservedState">
      <template v-slot:header>
        {{ $t('distanceToDisturbingSurfaces10cmObservedState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('distanceToDisturbingSurfaces10cmObservedState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.distanceToDisturbingSurfaces10cmObservedState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.distanceToDisturbingSurfaces10cmObservedNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.cableEntryWithReplacementPlateAndFlexedTileState">
      <template v-slot:header>
        {{ $t('cableEntryWithReplacementPlateAndFlexedTileState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('cableEntryWithReplacementPlateAndFlexedTileState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.cableEntryWithReplacementPlateAndFlexedTileState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.cableEntryWithReplacementPlateAndFlexedTileNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
      </div>
    </AccordionCard>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AccordionCard from '@/components/AccordionCard';
import AccordionCardOptionalInput from '@/components/AccordionCardOptionalInput';
import AttachmentGallery from '@/components/AttachmentGallery';
import DropzoneUpload from '@/components/DropzoneUpload';
import SelectButton from 'primevue/selectbutton';
import Textarea from 'primevue/textarea';

export default {
  name: 'ExistingDamage',
  components: {
    AccordionCard,
    AttachmentGallery,
    DropzoneUpload,
    SelectButton,
    Textarea,
    // AccordionCardOptionalInput,
  },
  props: {
    project: { type: Object },
    cards: { type: Object },
  },
  data() {
    return {
      isMinRailOverhangAfterEndClamp20mmAttachmentsUploadComplete: false,
      isDistancesToRidgeEavesNotLessThan20cmAttachmentsUploadComplete: false,
      isModuleClampAreasObservedAttachmentsUploadComplete: false,
      isModulesNoDamagesDirtShadingAttachmentsUploadComplete: false,
      isModuleClampsProperlyTightenedAttachmentsUploadComplete: false,
      isBreakthroughProperlySealedAttachmentsUploadComplete: false,
      isCableProperlyLaidInChimneyAttachmentsUploadComplete: false,
    };
  },
  computed: { ...mapGetters(['getEnumValues', 'getReportTemplateImages']) },

  methods: {
    ...mapActions(['uploadProjectAttachment', 'deleteProjectAttachment']),

    async onUploadProjectAttachment(payload) {
      await this.uploadProjectAttachment(payload);
    },

    async onDeleteProjectAttachment(event) {
      await this.deleteProjectAttachment({
        projectId: this.project.id,
        attachmentId: event.attachmentId,
        attachmentType: event.attachmentType,
      });
    },
  },
  async mounted() {
    this.$store.subscribeAction({
      after: (action, state) => {
        if (action.type === 'uploadProjectAttachment') {
          this.isMinRailOverhangAfterEndClamp20mmAttachmentsUploadComplete = true;
          this.isDistancesToRidgeEavesNotLessThan20cmAttachmentsUploadComplete = true;
          this.isModuleClampAreasObservedAttachmentsUploadComplete = true;
          this.isModulesNoDamagesDirtShadingAttachmentsUploadComplete = true;
          this.isModuleClampsProperlyTightenedAttachmentsUploadComplete = true;
          this.isBreakthroughProperlySealedAttachmentsUploadComplete = true;
          this.isCableProperlyLaidInChimneyAttachmentsUploadComplete = true;
        }
      },
    });
  },
};
</script>

<style lang="scss" scoped></style>
