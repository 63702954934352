<template>
  <div class="project-internal">
    <b-row>
      <b-col lg="5" md="5" sm="12">
        <legend>
          <strong>Vorbereitung</strong>
        </legend>

        <b-form-group
          v-if="isAdmin"
          horizontal
          :label="$t('client')"
          label-for="client"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
        >
          <multiselect
            :placeholder="$t('client')"
            v-model="project.client"
            id="employer"
            :options="clients"
            :allow-empty="false"
            :show-labels="false"
            :disabled="isClient"
            deselectLabel
            track-by="number"
            label="name"
          >
          </multiselect>
        </b-form-group>

        <b-form-group
          horizontal
          :label="$t('employer')"
          label-for="employer"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
        >
          <multiselect
            :placeholder="$t('employer')"
            v-model="project.employer"
            id="employer"
            :options="employers"
            :allow-empty="false"
            :show-labels="false"
            deselectLabel
            track-by="number"
            label="name"
          >
          </multiselect>
        </b-form-group>

        <b-form-group
          horizontal
          :label="$t('employerProjectNumber')"
          label-for="employerProjectNumber"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
        >
          <b-form-input
            v-model="project.employerProjectNumber"
            type="text"
            :placeholder="$t('employerProjectNumber')"
          />
        </b-form-group>

        <b-form-group
          horizontal
          :label="$t('deliveryAt')"
          label-for="deliveryAt"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
        >
          <Calendar
            v-model="deliveryAt"
            selectionMode="single"
            dateFormat="dd.mm.yy"
            :placeholder="$t('date')"
            :showWeek="true"
            :yearNavigator="true"
            :monthNavigator="true"
            yearRange="2019:2025"
          >
          </Calendar>
        </b-form-group>
        <b-form-group
          horizontal
          :label="$t('projectTypeState')"
          label-for="projectTypeState"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
        >
          <multiselect
            :placeholder="$t('projectTypeState')"
            v-model="project.projectTypeState"
            id="projectTypeState"
            :options="getEnumValues('ProjectTypeState')"
            :allow-empty="false"
            :show-labels="false"
            deselectLabel
          >
            <template slot="singleLabel" slot-scope="{ option }">
              <b-badge :variant="projectTypeStateColor(option)">
                {{ $t(option) }}
              </b-badge>
            </template>
            <template slot="option" slot-scope="{ option }">
              <b-badge :variant="projectTypeStateColor(option)">
                {{ $t(option) }}
              </b-badge>
            </template>
          </multiselect>
        </b-form-group>

        <b-form-group
          horizontal
          :label="$t('projectState')"
          label-for="projectState"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
        >
          <multiselect
            :placeholder="$t('projectState')"
            v-model="project.projectState"
            id="projectState"
            :options="getEnumValues('ProjectState')"
            :allow-empty="false"
            :show-labels="false"
            deselectLabel
          >
            <template slot="singleLabel" slot-scope="{ option }">
              <b-badge :variant="projectStateColor(option)">
                {{ $t(option) }}
              </b-badge>
            </template>
            <template slot="option" slot-scope="{ option }">
              <b-badge :variant="projectStateColor(option)">
                {{ $t(option) }}
              </b-badge>
            </template>
          </multiselect>
        </b-form-group>

        <b-form-group
          horizontal
          :label="$t('projectDescriptionState')"
          label-for="projectDescriptionState"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
        >
          <multiselect
            :placeholder="$t('projectDescriptionState')"
            v-model="project.projectDescriptionState"
            id="projectDescriptionState"
            :options="getEnumValues('DocumentState')"
            :allow-empty="false"
            :show-labels="false"
            deselectLabel
          >
            <template slot="singleLabel" slot-scope="{ option }">
              <b-badge :variant="documentStateColor(option)">
                {{ $t(option) }}
              </b-badge>
            </template>
            <template slot="option" slot-scope="{ option }">
              <b-badge :variant="documentStateColor(option)">
                {{ $t(option) }}
              </b-badge>
            </template>
          </multiselect>
        </b-form-group>

        <b-form-group
          horizontal
          :label="$t('mandateState')"
          label-for="mandateState"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
          :invalid-feedback="invalidFeedbackNA"
          :state="requirementsValidState('mandateState')"
        >
          <multiselect
            :placeholder="$t('mandateState')"
            v-model="project.mandateState"
            id="mandateState"
            :options="getEnumValues('DocumentState')"
            :allow-empty="false"
            :show-labels="false"
            deselectLabel
          >
            <template slot="singleLabel" slot-scope="{ option }">
              <b-badge :variant="documentStateColor(option)">
                {{ $t(option) }}
              </b-badge></template
            >
            <template slot="option" slot-scope="{ option }">
              <b-badge :variant="documentStateColor(option)">
                {{ $t(option) }}
              </b-badge></template
            >
          </multiselect>
        </b-form-group>
        <b-form-group
          horizontal
          :label="$t('layoutPlanState')"
          label-for="layoutPlanState"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
          :invalid-feedback="invalidFeedbackNA"
          :state="requirementsValidState('layoutPlanState')"
        >
          <multiselect
            :placeholder="$t('layoutPlanState')"
            v-model="project.layoutPlanState"
            id="layoutPlanState"
            :options="getEnumValues('DocumentState')"
            :allow-empty="false"
            :show-labels="false"
            deselectLabel
          >
            <template slot="singleLabel" slot-scope="{ option }">
              <b-badge :variant="documentStateColor(option)">
                {{ $t(option) }}
              </b-badge></template
            >
            <template slot="option" slot-scope="{ option }">
              <b-badge :variant="documentStateColor(option)">
                {{ $t(option) }}
              </b-badge></template
            >
          </multiselect>
        </b-form-group>
        <b-form-group
          horizontal
          :label="$t('installationImagesState')"
          label-for="installationImagesState"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
        >
          <multiselect
            :placeholder="$t('installationImagesState')"
            v-model="project.installationImagesState"
            id="installationImagesState"
            :options="getEnumValues('DocumentState')"
            :allow-empty="false"
            :show-labels="false"
            deselectLabel
          >
            <template slot="singleLabel" slot-scope="{ option }">
              <b-badge :variant="documentStateColor(option)">
                {{ $t(option) }}
              </b-badge></template
            >
            <template slot="option" slot-scope="{ option }">
              <b-badge :variant="documentStateColor(option)">
                {{ $t(option) }}
              </b-badge></template
            >
          </multiselect>
        </b-form-group>

        <div v-if="project.deferredState">
          <legend>
            <strong>{{ $t('deferredState') }}</strong>
          </legend>

          <div class="form-grid-container-left">
            <div class="label">{{ $t('deferredState') }}</div>
            <div>
              <multiselect
                :placeholder="$t('deferredState')"
                v-model="project.deferredState.deferred"
                :options="yesNo()"
                :allow-empty="false"
                :show-labels="false"
                deselectLabel
                @select="onChangeProjectDeferredState"
              >
                <template slot="singleLabel" slot-scope="{ option }">
                  <b-badge :variant="yesNoStateReversedColor(option)">
                    {{ $t(option) }}
                  </b-badge>
                </template>
                <template slot="option" slot-scope="{ option }">
                  <b-badge :variant="yesNoStateReversedColor(option)">
                    {{ $t(option) }}
                  </b-badge>
                </template>
              </multiselect>
            </div>

            <template
              v-if="
                project.deferredState.deferred === 'YES' ||
                  project.deferredState.description != '' ||
                  project.deferredState.comment != ''
              "
            >
              <div class="label">{{ $t('reason') }}</div>
              <div>
                {{ $t(project.deferredState.reason) }}
              </div>

              <div class="label">{{ $t('description') }}</div>
              <div>
                <Textarea v-model="project.deferredState.description" rows="3" cols="80" />
              </div>

              <div class="label">{{ $t('comment') }}</div>
              <div>
                <Textarea v-model="project.deferredState.comment" rows="3" cols="80" />
              </div>
            </template>
          </div>
        </div>

        <template
          v-if="
            project.vlink &&
              project.client &&
              (project.client.name === 'Q Power Solution' ||
                project.client.name === 'Q Power Solution Externe Reklamation' ||
                project.client.name === 'Q Cells Test Opolka / Teleson')
          "
        >
          <legend>
            <strong>{{ $t('vlink') }}</strong>
          </legend>

          <div class="form-grid-container-left">
            <div class="label">{{ $t('vlinkConnectedState') }}</div>
            <div>
              <b-badge :variant="yesNoColor(project.vlink.vlinkConnectedState)">
                {{ $t(project.vlink.vlinkConnectedState) }}
              </b-badge>
            </div>
            <div class="label">{{ $t('createdAt') }}</div>
            <div>
              <span v-if="project.vlink.createdAt">
                {{ $datetime(project.vlink.createdAt) }}
              </span>
              <span v-else>N/A</span>
            </div>
            <div class="label">{{ $t('lastUpdatedAt') }}</div>
            <div>
              <span v-if="project.vlink.updatedAt">
                {{ $datetime(project.vlink.updatedAt) }}
              </span>
              <span v-else>N/A</span>
            </div>
            <div class="label">{{ $t('vlinkProjectNr') }}</div>
            <div>
              <span v-if="project.vlink.projectNr">
                {{ project.vlink.projectNr }}
              </span>
              <span v-else>N/A</span>
            </div>
            <div class="label">{{ $t('vlinkProjectId') }}</div>
            <div>
              <span v-if="project.vlink.projectId"> {{ project.vlink.projectId }} </span>
              <span v-else>N/A</span>
            </div>
            <div class="label">{{ $t('vlinkToken') }}</div>
            <div>
              <span v-if="project.vlink.token"> {{ project.vlink.token }} </span>
              <span v-else>N/A</span>
            </div>
            <div class="label">{{ $t('url') }}</div>
            <div>
              <span v-if="project.vlink.url"> {{ project.vlink.url }} </span>
              <span v-else>N/A</span>
            </div>
          </div>
        </template>
      </b-col>

      <b-col v-if="false" lg="6" md="6" sm="12">
        <legend>
          <strong>Abschluss</strong>
        </legend>

        <b-form-group
          horizontal
          :label="$t('installationTeamDocumentationState')"
          label-for="installationTeamDocumentationState"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
        >
          <multiselect
            :placeholder="$t('installationTeamDocumentationState')"
            v-model="project.installationTeamDocumentationState"
            id="installationTeamDocumentationState"
            :options="getEnumValues('DocumentState')"
            :allow-empty="false"
            :show-labels="false"
            deselectLabel
          >
            <template slot="singleLabel" slot-scope="{ option }"
              ><b-badge :variant="documentStateColor(option)">
                {{ $t(option) }}
              </b-badge></template
            >
            <template slot="option" slot-scope="{ option }"
              ><b-badge :variant="documentStateColor(option)">
                {{ $t(option) }}
              </b-badge></template
            >
          </multiselect>
        </b-form-group>

        <b-form-group
          horizontal
          :label="$t('documentationSentToEmployerAt')"
          label-for="documentationSentToEmployerAt"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
        >
          <Calendar
            v-model="documentationSentToEmployerAt"
            selectionMode="single"
            dateFormat="dd.mm.yy"
            :placeholder="$t('date')"
            :showWeek="true"
            :yearNavigator="true"
            :monthNavigator="true"
            yearRange="2019:2025"
          >
          </Calendar>
        </b-form-group>
        <b-form-group
          horizontal
          :label="$t('invoiceSentToEmployerAt')"
          label-for="invoiceSentToEmployerAt"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
        >
          <Calendar
            v-model="invoiceSentToEmployerAt"
            selectionMode="single"
            dateFormat="dd.mm.yy"
            :placeholder="$t('date')"
            :showWeek="true"
            :yearNavigator="true"
            :monthNavigator="true"
            yearRange="2019:2025"
          >
          </Calendar>
        </b-form-group>

        <b-form-group
          horizontal
          :label="$t('employerInvoiceNumber')"
          label-for="employerInvoiceNumber"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
        >
          <b-form-input
            v-model="project.employerInvoiceNumber"
            type="text"
            :placeholder="$t('employerInvoiceNumber')"
          />
        </b-form-group>

        <b-form-group
          horizontal
          :label="$t('employerPayedAt')"
          label-for="employerPayedAt"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
        >
          <Calendar
            v-model="employerPayedAt"
            selectionMode="single"
            dateFormat="dd.mm.yy"
            :placeholder="$t('date')"
            :showWeek="true"
            :yearNavigator="true"
            :monthNavigator="true"
            yearRange="2019:2025"
          >
          </Calendar>
        </b-form-group>
        <b-form-group
          horizontal
          :label="$t('installationTeamPayedAt')"
          label-for="installationTeamPayedAt"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
        >
          <Calendar
            v-model="installationTeamPayedAt"
            selectionMode="single"
            dateFormat="dd.mm.yy"
            :placeholder="$t('date')"
            :showWeek="true"
            :yearNavigator="true"
            :monthNavigator="true"
            yearRange="2019:2025"
          >
          </Calendar>
        </b-form-group>
        <b-form-group
          horizontal
          :label="$t('employerClearanceAt')"
          label-for="employerClearanceAt"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
        >
          <div v-if="project.employerClearanceAt" class="form-text">
            {{ $datetime(project.employerClearanceAt) }}
          </div>
          <div v-else class="form-text">N/A</div>
        </b-form-group>
      </b-col>

      <b-col lg="7" md="7" sm="12">
        <legend>
          <strong>{{ $t('communication_with_employer') }}</strong>
        </legend>

        <div class="label">{{ $t('askEmployerNote') }}</div>
        <div class="dense-editor">
          <ckeditor
            :editor="editor"
            v-model="project.askEmployerNote"
            :config="editorConfig"
          ></ckeditor>
        </div>

        <div class="label">{{ $t('answerFromEmployerNote') }}</div>
        <div class="dense-editor">
          <ckeditor
            :editor="editor"
            v-model="project.answerFromEmployerNote"
            :config="editorConfig"
          ></ckeditor>
        </div>

        <legend>
          <strong>{{ $t('tasks') }}</strong>
        </legend>

        <TaskTable :project="project" />
      </b-col>
    </b-row>

    <Dialog
      header="Begründung erforderlich"
      :visible.sync="displayStateChangeConfirmation"
      :contentStyle="{ overflow: 'visible' }"
      :modal="true"
    >
      <div v-if="project.deferredState">
        <div class="flex-row">
          <div>{{ $t('reason') }}</div>
          <div>
            <Dropdown
              v-model="project.deferredState.reason"
              :options="getEnumValues('DeferredReason')"
              :placeholder="$t('select')"
            >
              <template #option="slotProps">
                <b-badge>
                  {{ $t(slotProps.option) }}
                </b-badge>
              </template>
              <template #value="slotProps">
                <b-badge>
                  {{ $t(slotProps.value) }}
                </b-badge>
              </template>
            </Dropdown>
          </div>
        </div>

        <div class="flex-row">
          <div>{{ $t('description') }}</div>
          <div>
            <Textarea v-model="project.deferredState.description" rows="3" cols="40" />
          </div>
        </div>
      </div>

      <template #footer>
        <Button
          class="btn btn-narrow btn-default ml-2 mb-2"
          @click="onCancelProjectDeferredStateChangeReason"
        >
          {{ $t('cancel') }}
        </Button>
        <Button
          class="btn btn-narrow btn-inverse ml-2 mb-2"
          @click="onSaveProjectDeferredStateChangeReason"
          :disabled="deferredStateOkButtonDisabled"
        >
          {{ $t('ok') }}
        </Button>
      </template>
    </Dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Multiselect from 'vue-multiselect';
import Calendar from 'primevue/calendar';
import TaskTable from '@/components/TaskTable';
import {
  getYesNoStateColor,
  getDocumentStateColor,
  getProjectStateColor,
  yesNoStateReversedColor,
  projectTypeStateColor,
} from '@/helpers/colors';
import Dialog from 'primevue/dialog';
import Textarea from 'primevue/textarea';
import { yesNo } from '@/helpers/enums';
import Dropdown from 'primevue/dropdown';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import InlineEditor from '@ckeditor/ckeditor5-build-inline';

export default {
  components: {
    Multiselect,
    Calendar,
    TaskTable,
    Dialog,
    Textarea,
    Dropdown,
    ckeditor: CKEditor.component,
  },
  props: {
    project: { type: Object },
  },
  data() {
    return {
      displayStateChangeConfirmation: false,
      projectStateBackup: null,
      editor: InlineEditor,
      editorConfig: {},
    };
  },
  computed: {
    ...mapGetters('auth', ['isAdmin', 'getCurrentUser', 'isClient']),
    ...mapGetters(['getEnumValues', 'getClients', 'getEmployers']),
    employers() {
      // return this.project.client && this.project.client.employers
      //   ? this.project.client.employers
      //   : [];
      return this.getEmployers.filter((employer) => {
        // console.log(employer);
        if (
          employer.client &&
          this.project.client &&
          employer.client.id === this.project.client.id
        ) {
          return employer;
        }
      });
    },
    clients() {
      return this.isClient ? [this.getCurrentUser.client] : this.getClients;
    },
    deliveryAt: {
      get() {
        return this.$calendarFormat(this.project.deliveryAt);
      },
      set(value) {
        this.project.deliveryAt = value;
      },
    },
    /**
     * @todo DRY
     */
    invalidFeedbackNA() {
      return 'Voraussetzung NA';
    },
    deferredStateOkButtonDisabled() {
      return this.project.deferredState && this.project.deferredState.description !== ''
        ? false
        : true;
    },
  },
  methods: {
    ...mapActions(['fetchEnumValues', 'fetchClients', 'fetchEmployersByClient', 'fetchEmployers']),
    yesNoColor(state) {
      return getYesNoStateColor(state);
    },
    documentStateColor(state) {
      return getDocumentStateColor(state);
    },
    projectStateColor(state) {
      return getProjectStateColor(state);
    },

    /**
     * if project.deferredState is changed to yes a reason has to be given
     * @param {*} newState
     */
    onChangeProjectDeferredState(newState) {
      console.log('onChangeProjectDeferredState()', newState);

      if (newState === 'YES') {
        this.displayStateChangeConfirmation = true;
      }
    },

    onCancelProjectDeferredStateChangeReason() {
      // console.log(
      //   'onCancelProjectStateChangeReason()',
      //   this.project.projectState,
      //   this.projectStateBackup
      // );
      this.project.deferredState.deferred = 'NO';
      this.displayStateChangeConfirmation = false;
    },
    onSaveProjectDeferredStateChangeReason() {
      // console.log('onSaveProjectStateChangeReason()', this.project.deferredState);
      this.displayStateChangeConfirmation = false;
    },

    async setup() {
      if (this.isAdmin) {
        await this.fetchClients();
        await this.fetchEmployers();
      }
      if (this.isClient) {
        await this.fetchEmployersByClient(this.getCurrentUser.client.id);
      }
      await this.fetchEnumValues('ProjectState');
      await this.fetchEnumValues('ProjectTypeState');
      await this.fetchEnumValues('DocumentState');
      await this.fetchEnumValues('DeferredReason');

      /** init deferredState */
      if (!this.project.deferredState) {
        this.project.deferredState = {
          deferred: 'NO',
          reason: 'MISC',
          description: '',
          comment: '',
        };
      }

      // cache current projectState
      this.projectStateBackup = this.project.projectState;
    },
    /**
     * check requirementsValidState to show mark input fields
     * @todo DRY
     */
    requirementsValidState(field) {
      if (!this.project.netRegistrationRequirements) return false;

      const requirement = this.project.netRegistrationRequirements.find(
        (requirement) => requirement.name === field
      );
      return requirement && requirement.fullfilledState === 'YES' ? true : false;
    },
    yesNo,
    getYesNoStateColor,
    yesNoStateReversedColor,
    projectTypeStateColor,
  },

  async mounted() {
    await this.setup();
  },
};
</script>

<style scoped lang="scss">
.form-grid-container-left {
  display: grid;
  grid-template-columns: 1fr 2fr;
  column-gap: 1em;
  row-gap: 1em;
}
.form-grid-container-left .label {
  text-align: right;
}
.dense-editor {
  height: auto;
  max-height: 300px;
  overflow-y: auto;
}

.ck-editor__editable_inline {
  min-height: 110px;
  height: auto;
  max-height: 300px;
  overflow-y: auto;
}
</style>
