<template>
  <div>
    <AccordionCard :state="cards.crimpingDoneProperlyState">
      <template v-slot:header>
        {{ $t('crimpingDoneProperlyState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('crimpingDoneProperlyState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.crimpingDoneProperlyState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.crimpingDoneProperlyNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.ussWiredProperlyInCabinetState">
      <template v-slot:header>
        {{ $t('ussWiredProperlyInCabinetState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('ussWiredProperlyInCabinetState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.ussWiredProperlyInCabinetState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.ussWiredProperlyInCabinetNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
        <div class="flex-row">
          <div class="flex-column name">{{ $t('image_upload') }}</div>
          <div class="flex-row">
            <div class="flex-column select">
              <div>
                {{ $t('templateAttachments') }}:
                <AttachmentGallery
                  :attachments="getReportTemplateImages[53].templateAttachments"
                  :deletable="false"
                  :sortByName="false"
                  :showFilename="false"
                ></AttachmentGallery>
              </div>
            </div>
          </div>
          <div class="flex-column select">
            <AttachmentGallery
              :attachments="project.ussWiredProperlyInCabinetAttachments"
              attachmentType="ussWiredProperlyInCabinetAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
              :sortByName="false"
            ></AttachmentGallery>
            <DropzoneUpload
              class="mt-2"
              :complete="isUssWiredProperlyInCabinetAttachmentsUploadComplete"
              :parentId="project.id"
              parentType="ussWiredProperlyInCabinetAttachments"
              :uploadCallback="onUploadProjectAttachment"
              v-on:dropzone-upload:reset="
                isUssWiredProperlyInCabinetAttachmentsUploadComplete = false
              "
              acceptedFileTypes="image/*"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.ussBoxWiredProperlyState">
      <template v-slot:header>
        {{ $t('ussBoxWiredProperlyState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('ussBoxWiredProperlyState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.ussBoxWiredProperlyState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.ussBoxWiredProperlyNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
        <div class="flex-row">
          <div class="flex-column name">{{ $t('image_upload') }}</div>
          <div class="flex-row">
            <div class="flex-column select">
              <div>
                {{ $t('templateAttachments') }}:
                <AttachmentGallery
                  :attachments="getReportTemplateImages[54].templateAttachments"
                  :deletable="false"
                  :sortByName="false"
                  :showFilename="false"
                ></AttachmentGallery>
              </div>
            </div>
          </div>
          <div class="flex-column select">
            <AttachmentGallery
              :attachments="project.ussBoxWiredProperlyAttachments"
              attachmentType="ussBoxWiredProperlyAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
              :sortByName="false"
            ></AttachmentGallery>
            <DropzoneUpload
              class="mt-2"
              :complete="isUssBoxWiredProperlyAttachmentsUploadComplete"
              :parentId="project.id"
              parentType="ussBoxWiredProperlyAttachments"
              :uploadCallback="onUploadProjectAttachment"
              v-on:dropzone-upload:reset="isUssBoxWiredProperlyAttachmentsUploadComplete = false"
              acceptedFileTypes="image/*"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.bendingRadiiMaintainedState">
      <template v-slot:header>
        {{ $t('bendingRadiiMaintainedState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('bendingRadiiMaintainedState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.bendingRadiiMaintainedState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.bendingRadiiMaintainedNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
        <div class="flex-row">
          <div class="flex-column name">{{ $t('image_upload') }}</div>
          <div class="flex-row">
            <div class="flex-column select">
              <div>
                {{ $t('templateAttachments') }}:
                <AttachmentGallery
                  :attachments="getReportTemplateImages[55].templateAttachments"
                  :deletable="false"
                  :sortByName="false"
                  :showFilename="false"
                ></AttachmentGallery>
              </div>
            </div>
          </div>

          <div class="flex-column select">
            <AttachmentGallery
              :attachments="project.bendingRadiiMaintainedAttachments"
              attachmentType="bendingRadiiMaintainedAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
              :sortByName="false"
            ></AttachmentGallery>
            <DropzoneUpload
              class="mt-2"
              :complete="isBendingRadiiMaintainedAttachmentsUploadComplete"
              :parentId="project.id"
              parentType="bendingRadiiMaintainedAttachments"
              :uploadCallback="onUploadProjectAttachment"
              v-on:dropzone-upload:reset="isBendingRadiiMaintainedAttachmentsUploadComplete = false"
              acceptedFileTypes="image/*"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.correctWiringOfMateboxState">
      <template v-slot:header>
        {{ $t('correctWiringOfMateboxState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('correctWiringOfMateboxState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.correctWiringOfMateboxState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.correctWiringOfMateboxNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
        <div class="flex-row">
          <div class="flex-column name">{{ $t('image_upload') }}</div>
          <div class="flex-row">
            <div class="flex-column select">
              <div>
                {{ $t('templateAttachments') }}:
                <AttachmentGallery
                  :attachments="getReportTemplateImages[56].templateAttachments"
                  :deletable="false"
                  :sortByName="false"
                  :showFilename="false"
                ></AttachmentGallery>
              </div>
            </div>
          </div>

          <div class="flex-column select">
            <AttachmentGallery
              :attachments="project.correctWiringOfMateboxAttachments"
              attachmentType="correctWiringOfMateboxAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
              :sortByName="false"
            ></AttachmentGallery>
            <DropzoneUpload
              class="mt-2"
              :complete="isCorrectWiringOfMateboxAttachmentsUploadComplete"
              :parentId="project.id"
              parentType="correctWiringOfMateboxAttachments"
              :uploadCallback="onUploadProjectAttachment"
              v-on:dropzone-upload:reset="isCorrectWiringOfMateboxAttachmentsUploadComplete = false"
              acceptedFileTypes="image/*"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.correctWiringOfBatteryState">
      <template v-slot:header>
        {{ $t('correctWiringOfBatteryState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('correctWiringOfBatteryState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.correctWiringOfBatteryState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.correctWiringOfBatteryNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
        <div class="flex-row">
          <div class="flex-column name">{{ $t('image_upload') }}</div>
          <div class="flex-row">
            <div class="flex-column select">
              <div>
                {{ $t('templateAttachments') }}:
                <AttachmentGallery
                  :attachments="getReportTemplateImages[57].templateAttachments"
                  :deletable="false"
                  :sortByName="false"
                  :showFilename="false"
                ></AttachmentGallery>
              </div>
            </div>
          </div>
          <div class="flex-column select">
            <AttachmentGallery
              :attachments="project.correctWiringOfBatteryAttachments"
              attachmentType="correctWiringOfBatteryAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
              :sortByName="false"
            ></AttachmentGallery>
            <DropzoneUpload
              class="mt-2"
              :complete="isCorrectWiringOfBatteryAttachmentsUploadComplete"
              :parentId="project.id"
              parentType="correctWiringOfBatteryAttachments"
              :uploadCallback="onUploadProjectAttachment"
              v-on:dropzone-upload:reset="isCorrectWiringOfBatteryAttachmentsUploadComplete = false"
              acceptedFileTypes="image/*"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.allComponentsProperlyGroundedState">
      <template v-slot:header>
        {{ $t('allComponentsProperlyGroundedState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('allComponentsProperlyGroundedState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.allComponentsProperlyGroundedState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.allComponentsProperlyGroundedNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
        <div class="flex-row">
          <div class="flex-column name">{{ $t('image_upload') }}</div>
          <div class="flex-row">
            <div class="flex-column select">
              <div>
                {{ $t('templateAttachments') }}:
                <AttachmentGallery
                  :attachments="getReportTemplateImages[58].templateAttachments"
                  :deletable="false"
                  :sortByName="false"
                  :showFilename="false"
                ></AttachmentGallery>
              </div>
            </div>
          </div>

          <div class="flex-column select">
            <AttachmentGallery
              :attachments="project.allComponentsProperlyGroundedAttachments"
              attachmentType="allComponentsProperlyGroundedAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
              :sortByName="false"
            ></AttachmentGallery>
            <DropzoneUpload
              class="mt-2"
              :complete="isAllComponentsProperlyGroundedAttachmentsUploadComplete"
              :parentId="project.id"
              parentType="allComponentsProperlyGroundedAttachments"
              :uploadCallback="onUploadProjectAttachment"
              v-on:dropzone-upload:reset="
                isAllComponentsProperlyGroundedAttachmentsUploadComplete = false
              "
              acceptedFileTypes="image/*"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.correctColorCodingAndLabelingState">
      <template v-slot:header>
        {{ $t('correctColorCodingAndLabelingState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('correctColorCodingAndLabelingState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.correctColorCodingAndLabelingState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.correctColorCodingAndLabelingNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.correctWiringOfPvConnectorsState">
      <template v-slot:header>
        {{ $t('correctWiringOfPvConnectorsState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('correctWiringOfPvConnectorsState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.correctWiringOfPvConnectorsState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.correctWiringOfPvConnectorsNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.cableDuctProperlySizedAndCutState">
      <template v-slot:header>
        {{ $t('cableDuctProperlySizedAndCutState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('cableDuctProperlySizedAndCutState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.cableDuctProperlySizedAndCutState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.cableDuctProperlySizedAndCutNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.nhHakSelectiveToSlsState">
      <template v-slot:header>
        {{ $t('nhHakSelectiveToSlsState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('nhHakSelectiveToSlsState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.nhHakSelectiveToSlsState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.nhHakSelectiveToSlsNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.checkNetworkTypeState">
      <template v-slot:header>
        {{ $t('checkNetworkTypeState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('checkNetworkTypeState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.checkNetworkTypeState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.checkNetworkTypeNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.earthRodConnectedToHpasState">
      <template v-slot:header>
        {{ $t('earthRodConnectedToHpasState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('earthRodConnectedToHpasState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.earthRodConnectedToHpasState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.earthRodConnectedToHpasNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.connectionBetweenHpasAndPasProperlyMadeState">
      <template v-slot:header>
        {{ $t('connectionBetweenHpasAndPasProperlyMadeState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('connectionBetweenHpasAndPasProperlyMadeState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.connectionBetweenHpasAndPasProperlyMadeState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.connectionBetweenHpasAndPasProperlyMadeNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.insulationProperlyStrippedState">
      <template v-slot:header>
        {{ $t('insulationProperlyStrippedState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('insulationProperlyStrippedState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.insulationProperlyStrippedState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.insulationProperlyStrippedNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
      </div>
    </AccordionCard>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AccordionCard from '@/components/AccordionCard';
import SelectButton from 'primevue/selectbutton';
import Textarea from 'primevue/textarea';
import DropzoneUpload from '@/components/DropzoneUpload';
import AttachmentGallery from '@/components/AttachmentGallery';

export default {
  name: 'SubstructureQualityReportAccordions',
  components: {
    AccordionCard,
    SelectButton,
    Textarea,
    DropzoneUpload,
    AttachmentGallery,
  },
  data() {
    return {
      /** track Upload states for clearing dropzone preview */
      isUssWiredProperlyInCabinetAttachmentsUploadComplete: false,
      isUssBoxWiredProperlyAttachmentsUploadComplete: false,
      isBendingRadiiMaintainedAttachmentsUploadComplete: false,
      isCorrectWiringOfMateboxAttachmentsUploadComplete: false,
      isCorrectWiringOfBatteryAttachmentsUploadComplete: false,
      isAllComponentsProperlyGroundedAttachmentsUploadComplete: false,
    };
  },
  computed: { ...mapGetters(['getEnumValues', 'getReportTemplateImages']) },
  props: {
    project: { type: Object },
    cards: { type: Object },
  },
  methods: {
    ...mapActions(['uploadProjectAttachment', 'deleteProjectAttachment']),

    async onUploadProjectAttachment(payload) {
      await this.uploadProjectAttachment(payload);
    },

    async onDeleteProjectAttachment(event) {
      await this.deleteProjectAttachment({
        projectId: this.project.id,
        attachmentId: event.attachmentId,
        attachmentType: event.attachmentType,
      });
    },
  },
  async mounted() {
    // after upload is complete emit event to clear dropzone preview
    this.$store.subscribeAction({
      after: (action, state) => {
        if (action.type === 'uploadProjectAttachment') {
          this.isUssWiredProperlyInCabinetAttachmentsUploadComplete = true;
          this.isUssBoxWiredProperlyAttachmentsUploadComplete = true;
          this.isBendingRadiiMaintainedAttachmentsUploadComplete = true;
          this.isCorrectWiringOfMateboxAttachmentsUploadComplete = true;
          this.isCorrectWiringOfBatteryAttachmentsUploadComplete = true;
          this.isAllComponentsProperlyGroundedAttachmentsUploadComplete = true;
        }
      },
    });
  },
};
</script>

<style lang="scss" scoped></style>
