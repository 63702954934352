<template>
  <div>
    <AccordionCard :state="cards.ballastingAccordingToProjectReportState">
      <template v-slot:header>
        {{ $t('ballastingAccordingToProjectReportState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('ballastingAccordingToProjectReportState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.ballastingAccordingToProjectReportState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.ballastingAccordingToProjectReportNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
        <div class="flex-row">
          <div class="flex-column name">{{ $t('image_upload') }}</div>
          <div class="flex-column select">
            <AttachmentGallery
              :attachments="project.ballastingAccordingToProjectReportAttachments"
              attachmentType="ballastingAccordingToProjectReportAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
              :sortByName="false"
            ></AttachmentGallery>
            <DropzoneUpload
              class="mt-2"
              :complete="isBallastingAccordingToProjectReportAttachmentsUploadComplete"
              :parentId="project.id"
              parentType="ballastingAccordingToProjectReportAttachments"
              :uploadCallback="onUploadProjectAttachment"
              v-on:dropzone-upload:reset="
                isBallastingAccordingToProjectReportAttachmentsUploadComplete = false
              "
              acceptedFileTypes="image/*"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.minimumModuleBearingAreaMaintainedState">
      <template v-slot:header>
        {{ $t('minimumModuleBearingAreaMaintainedState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('minimumModuleBearingAreaMaintainedState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.minimumModuleBearingAreaMaintainedState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.minimumModuleBearingAreaMaintainedNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
        <div class="flex-row">
          <div class="flex-column name">{{ $t('image_upload') }}</div>
          <div class="flex-row">
            <div class="flex-column select">
              <div>
                {{ $t('templateAttachments') }}:
                <AttachmentGallery
                  :attachments="getReportTemplateImages[73].templateAttachments"
                  :deletable="false"
                  :sortByName="false"
                  :showFilename="false"
                ></AttachmentGallery>
              </div>
            </div>
          </div>
          <div class="flex-column select">
            <AttachmentGallery
              :attachments="project.minimumModuleBearingAreaMaintainedAttachments"
              attachmentType="minimumModuleBearingAreaMaintainedAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
              :sortByName="false"
            ></AttachmentGallery>
            <DropzoneUpload
              class="mt-2"
              :complete="isMinimumModuleBearingAreaMaintainedAttachmentsUploadComplete"
              :parentId="project.id"
              parentType="minimumModuleBearingAreaMaintainedAttachments"
              :uploadCallback="onUploadProjectAttachment"
              v-on:dropzone-upload:reset="
                isMinimumModuleBearingAreaMaintainedAttachmentsUploadComplete = false
              "
              acceptedFileTypes="image/*"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.qFlatProperlyConnectedState">
      <template v-slot:header>
        {{ $t('qFlatProperlyConnectedState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('qFlatProperlyConnectedState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.qFlatProperlyConnectedState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.qFlatProperlyConnectedNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
        <div class="flex-row">
          <div class="flex-column name">{{ $t('image_upload') }}</div>
          <div class="flex-row">
            <div class="flex-column select">
              <div>
                {{ $t('templateAttachments') }}:
                <AttachmentGallery
                  :attachments="getReportTemplateImages[75].templateAttachments"
                  :deletable="false"
                  :sortByName="false"
                  :showFilename="false"
                ></AttachmentGallery>
              </div>
            </div>
          </div>
          <div class="flex-column select">
            <AttachmentGallery
              :attachments="project.qFlatProperlyConnectedAttachments"
              attachmentType="qFlatProperlyConnectedAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
              :sortByName="false"
            ></AttachmentGallery>
            <DropzoneUpload
              class="mt-2"
              :complete="isQFlatProperlyConnectedAttachmentsUploadComplete"
              :parentId="project.id"
              parentType="qFlatProperlyConnectedAttachments"
              :uploadCallback="onUploadProjectAttachment"
              v-on:dropzone-upload:reset="isQFlatProperlyConnectedAttachmentsUploadComplete = false"
              acceptedFileTypes="image/*"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.distanceToDisturbingSurfacesMaintainedState">
      <template v-slot:header>
        {{ $t('distanceToDisturbingSurfacesMaintainedState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('distanceToDisturbingSurfacesMaintainedState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.distanceToDisturbingSurfacesMaintainedState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.distanceToDisturbingSurfacesMaintainedNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
        <div class="flex-row">
          <div class="flex-column name">{{ $t('image_upload') }}</div>
          <div class="flex-row">
            <div class="flex-column select">
              <div>
                {{ $t('templateAttachments') }}:
                <AttachmentGallery
                  :attachments="getReportTemplateImages[76].templateAttachments"
                  :deletable="false"
                  :sortByName="false"
                  :showFilename="false"
                ></AttachmentGallery>
              </div>
            </div>
          </div>
          <div class="flex-column select">
            <AttachmentGallery
              :attachments="project.distanceToDisturbingSurfacesMaintainedAttachments"
              attachmentType="distanceToDisturbingSurfacesMaintainedAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
              :sortByName="false"
            ></AttachmentGallery>
            <DropzoneUpload
              class="mt-2"
              :complete="isDistanceToDisturbingSurfacesMaintainedAttachmentsUploadComplete"
              :parentId="project.id"
              parentType="distanceToDisturbingSurfacesMaintainedAttachments"
              :uploadCallback="onUploadProjectAttachment"
              v-on:dropzone-upload:reset="
                isDistanceToDisturbingSurfacesMaintainedAttachmentsUploadComplete = false
              "
              acceptedFileTypes="image/*"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.flatRoofmoduleClampsProperlyTightenedState">
      <template v-slot:header>
        {{ $t('flatRoofmoduleClampsProperlyTightenedState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('flatRoofmoduleClampsProperlyTightenedState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.flatRoofmoduleClampsProperlyTightenedState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.flatRoofmoduleClampsProperlyTightenedNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.modulesNoDamageDirtShadingState">
      <template v-slot:header>
        {{ $t('modulesNoDamageDirtShadingState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('modulesNoDamageDirtShadingState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.modulesNoDamageDirtShadingState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.modulesNoDamageDirtShadingNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
        <div class="flex-row">
          <div class="flex-column name">{{ $t('image_upload') }}</div>
          <div class="flex-row">
            <div class="flex-column select">
              <div>
                {{ $t('templateAttachments') }}:
                <AttachmentGallery
                  :attachments="getReportTemplateImages[77].templateAttachments"
                  :deletable="false"
                  :sortByName="false"
                  :showFilename="false"
                ></AttachmentGallery>
              </div>
            </div>
          </div>
          <div class="flex-column select">
            <AttachmentGallery
              :attachments="project.modulesNoDamageDirtShadingAttachments"
              attachmentType="modulesNoDamageDirtShadingAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
              :sortByName="false"
            ></AttachmentGallery>
            <DropzoneUpload
              class="mt-2"
              :complete="isModulesNoDamageDirtShadingAttachmentsUploadComplete"
              :parentId="project.id"
              parentType="modulesNoDamageDirtShadingAttachments"
              :uploadCallback="onUploadProjectAttachment"
              v-on:dropzone-upload:reset="
                isModulesNoDamageDirtShadingAttachmentsUploadComplete = false
              "
              acceptedFileTypes="image/*"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.typeOfCableProtectionOutdoorsState">
      <template v-slot:header>
        {{ $t('typeOfCableProtectionOutdoorsState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('typeOfCableProtectionOutdoorsState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.typeOfCableProtectionOutdoorsState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.typeOfCableProtectionOutdoorsNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.correctSelectionOfPvConnectorsState">
      <template v-slot:header>
        {{ $t('correctSelectionOfPvConnectorsState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('correctSelectionOfPvConnectorsState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.correctSelectionOfPvConnectorsState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.correctSelectionOfPvConnectorsNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
        <div class="flex-row">
          <div class="flex-column name">{{ $t('image_upload') }}</div>
          <div class="flex-row">
            <div class="flex-column select">
              <div>
                {{ $t('templateAttachments') }}:
                <AttachmentGallery
                  :attachments="getReportTemplateImages[78].templateAttachments"
                  :deletable="false"
                  :sortByName="false"
                  :showFilename="false"
                ></AttachmentGallery>
              </div>
            </div>
          </div>
          <div class="flex-column select">
            <AttachmentGallery
              :attachments="project.correctSelectionOfPvConnectorsAttachments"
              attachmentType="correctSelectionOfPvConnectorsAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
              :sortByName="false"
            ></AttachmentGallery>
            <DropzoneUpload
              class="mt-2"
              :complete="isCorrectSelectionOfPvConnectorsAttachmentsUploadComplete"
              :parentId="project.id"
              parentType="correctSelectionOfPvConnectorsAttachments"
              :uploadCallback="onUploadProjectAttachment"
              v-on:dropzone-upload:reset="
                isCorrectSelectionOfPvConnectorsAttachmentsUploadComplete = false
              "
              acceptedFileTypes="image/*"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.cablesDoNotRestOnRoofState">
      <template v-slot:header>
        {{ $t('cablesDoNotRestOnRoofState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('cablesDoNotRestOnRoofState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.cablesDoNotRestOnRoofState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.cablesDoNotRestOnRoofNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
      </div>
    </AccordionCard>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AccordionCard from '@/components/AccordionCard';
import SelectButton from 'primevue/selectbutton';
import Textarea from 'primevue/textarea';
import DropzoneUpload from '@/components/DropzoneUpload';
import AttachmentGallery from '@/components/AttachmentGallery';

export default {
  name: 'SubstructureQualityReportAccordions',
  components: {
    AccordionCard,
    SelectButton,
    Textarea,
    DropzoneUpload,
    AttachmentGallery,
  },
  data() {
    return {
      /** track Upload states for clearing dropzone preview */
      isBallastingAccordingToProjectReportAttachmentsUploadComplete: false,
      isMinimumModuleBearingAreaMaintainedAttachmentsUploadComplete: false,
      isQFlatProperlyConnectedAttachmentsUploadComplete: false,
      isDistanceToDisturbingSurfacesMaintainedAttachmentsUploadComplete: false,
      isModulesNoDamageDirtShadingAttachmentsUploadComplete: false,
      isCorrectSelectionOfPvConnectorsAttachmentsUploadComplete: false,
    };
  },
  computed: { ...mapGetters(['getEnumValues', 'getReportTemplateImages']) },
  props: {
    project: { type: Object },
    cards: { type: Object },
  },
  methods: {
    ...mapActions(['uploadProjectAttachment', 'deleteProjectAttachment']),

    async onUploadProjectAttachment(payload) {
      await this.uploadProjectAttachment(payload);
    },

    async onDeleteProjectAttachment(event) {
      await this.deleteProjectAttachment({
        projectId: this.project.id,
        attachmentId: event.attachmentId,
        attachmentType: event.attachmentType,
      });
    },
  },
  async mounted() {
    // after upload is complete emit event to clear dropzone preview
    this.$store.subscribeAction({
      after: (action, state) => {
        if (action.type === 'uploadProjectAttachment') {
          this.isBallastingAccordingToProjectReportAttachmentsUploadComplete = true;
          this.isMinimumModuleBearingAreaMaintainedAttachmentsUploadComplete = true;
          this.isQFlatProperlyConnectedAttachmentsUploadComplete = true;
          this.isDistanceToDisturbingSurfacesMaintainedAttachmentsUploadComplete = true;
          this.isModulesNoDamageDirtShadingAttachmentsUploadComplete = true;
          this.isCorrectSelectionOfPvConnectorsAttachmentsUploadComplete = true;
        }
      },
    });
  },
};
</script>

<style lang="scss" scoped></style>
