<template>
  <div>
    <AccordionCard :state="cards.documentationProjectReportPresentState">
      <template v-slot:header>
        {{ $t('documentationProjectReportPresentState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('documentationProjectReportPresentState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.documentationProjectReportPresentState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.documentationProjectReportPresentNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.customerSpecialRequestsConsideredState">
      <template v-slot:header>
        {{ $t('customerSpecialRequestsConsideredState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('customerSpecialRequestsConsideredState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.customerSpecialRequestsConsideredState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.customerSpecialRequestsConsideredNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.flatRooflightningProtectionPresentState">
      <template v-slot:header>
        {{ $t('flatRooflightningProtectionPresentState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('flatRooflightningProtectionPresentState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.flatRooflightningProtectionPresentState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.flatRooflightningProtectionPresentNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
        <div class="flex-row">
          <div class="flex-column name">{{ $t('image_upload') }}</div>
          <div class="flex-column select">
            <AttachmentGallery
              :attachments="project.flatRoofLightningProtectionPresentAttachments"
              attachmentType="flatRoofLightningProtectionPresentAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
              :sortByName="false"
            ></AttachmentGallery>
            <DropzoneUpload
              class="mt-2"
              :complete="isFlatRoofLightningProtectionPresentAttachmentsUploadComplete"
              :parentId="project.id"
              parentType="flatRoofLightningProtectionPresentAttachments"
              :uploadCallback="onUploadProjectAttachment"
              v-on:dropzone-upload:reset="
                isFlatRoofLightningProtectionPresentAttachmentsUploadComplete = false
              "
              acceptedFileTypes="image/*"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.properGroundingFlatRoofSystemState">
      <template v-slot:header>
        {{ $t('properGroundingFlatRoofSystemState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('properGroundingFlatRoofSystemState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.properGroundingFlatRoofSystemState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.properGroundingFlatRoofSystemNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
        <div class="flex-row">
          <div class="flex-column name">{{ $t('image_upload') }}</div>
          <div class="flex-row">
            <div class="flex-column select">
              <div>
                {{ $t('templateAttachments') }}:
                <AttachmentGallery
                  :attachments="getReportTemplateImages[86].templateAttachments"
                  :deletable="false"
                  :sortByName="false"
                  :showFilename="false"
                ></AttachmentGallery>
              </div>
            </div>
          </div>
          <div class="flex-column select">
            <AttachmentGallery
              :attachments="project.properGroundingFlatRoofSystemAttachments"
              attachmentType="properGroundingFlatRoofSystemAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
              :sortByName="false"
            ></AttachmentGallery>
            <DropzoneUpload
              class="mt-2"
              :complete="isProperGroundingFlatRoofSystemAttachmentsUploadComplete"
              :parentId="project.id"
              parentType="properGroundingFlatRoofSystemAttachments"
              :uploadCallback="onUploadProjectAttachment"
              v-on:dropzone-upload:reset="
                isProperGroundingFlatRoofSystemAttachmentsUploadComplete = false
              "
              acceptedFileTypes="image/*"
            />
          </div>
        </div>
      </div>
    </AccordionCard>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AccordionCard from '@/components/AccordionCard';
import DropzoneUpload from '@/components/DropzoneUpload';
import AttachmentGallery from '@/components/AttachmentGallery';
import SelectButton from 'primevue/selectbutton';
import Textarea from 'primevue/textarea';

export default {
  name: 'SubstructureQualityReportAccordions',
  components: {
    AccordionCard,
    SelectButton,
    Textarea,
    DropzoneUpload,
    AttachmentGallery,
  },
  props: {
    project: { type: Object },
    cards: { type: Object },
  },
  data() {
    return {
      /** track Upload states for clearing dropzone preview */
      isFlatRoofLightningProtectionPresentAttachmentsUploadComplete: false,
      isProperGroundingFlatRoofSystemAttachmentsUploadComplete: false,
    };
  },
  computed: { ...mapGetters(['getEnumValues', 'getReportTemplateImages']) },

  methods: {
    ...mapActions(['uploadProjectAttachment', 'deleteProjectAttachment']),

    async onUploadProjectAttachment(payload) {
      await this.uploadProjectAttachment(payload);
    },

    async onDeleteProjectAttachment(event) {
      await this.deleteProjectAttachment({
        projectId: this.project.id,
        attachmentId: event.attachmentId,
        attachmentType: event.attachmentType,
      });
    },
  },
  async mounted() {
    // after upload is complete emit event to clear dropzone preview
    this.$store.subscribeAction({
      after: (action, state) => {
        if (action.type === 'uploadProjectAttachment') {
          this.isFlatRoofLightningProtectionPresentAttachmentsUploadComplete = true;
          this.isProperGroundingFlatRoofSystemAttachmentsUploadComplete = true;
        }
      },
    });
  },
};
</script>

<style lang="scss" scoped></style>
