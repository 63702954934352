<template>
  <div>
    <AccordionCard :state="cards.pvSystemLabeledInOrAtDistributionBoardState">
      <template v-slot:header>
        {{ $t('pvSystemLabeledInOrAtDistributionBoardState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('pvSystemLabeledInOrAtDistributionBoardState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.pvSystemLabeledInOrAtDistributionBoardState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.pvSystemLabeledInOrAtDistributionBoardNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
        <div class="flex-row">
          <div class="flex-column name">{{ $t('image_upload') }}</div>
          <div class="flex-column select">
            <AttachmentGallery
              :attachments="project.pvSystemLabeledInOrAtDistributionBoardAttachments"
              attachmentType="pvSystemLabeledInOrAtDistributionBoardAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
              :sortByName="false"
            ></AttachmentGallery>
            <DropzoneUpload
              class="mt-2"
              :complete="isPvSystemLabeledInOrAtDistributionBoardAttachmentsUploadComplete"
              :parentId="project.id"
              parentType="pvSystemLabeledInOrAtDistributionBoardAttachments"
              :uploadCallback="onUploadProjectAttachment"
              v-on:dropzone-upload:reset="
                isPvSystemLabeledInOrAtDistributionBoardAttachmentsUploadComplete = false
              "
              acceptedFileTypes="image/*"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.testReportAvailableWithAllMeasurementsState">
      <template v-slot:header>
        {{ $t('testReportAvailableWithAllMeasurementsState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('testReportAvailableWithAllMeasurementsState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.testReportAvailableWithAllMeasurementsState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.testReportAvailableWithAllMeasurementsNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
        <div class="flex-row">
          <div class="flex-column name">{{ $t('image_upload') }}</div>
          <div class="flex-row">
            <div class="flex-column select">
              <div>
                {{ $t('templateAttachments') }}:
                <AttachmentGallery
                  :attachments="getReportTemplateImages[85].templateAttachments"
                  :deletable="false"
                  :sortByName="false"
                  :showFilename="false"
                ></AttachmentGallery>
              </div>
            </div>
          </div>
          <div class="flex-column select">
            <AttachmentGallery
              :attachments="project.testReportAvailableWithAllMeasurementsAttachments"
              attachmentType="testReportAvailableWithAllMeasurementsAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
              :sortByName="false"
            ></AttachmentGallery>
            <DropzoneUpload
              class="mt-2"
              :complete="isTestReportAvailableWithAllMeasurementsAttachmentsUploadComplete"
              :parentId="project.id"
              parentType="testReportAvailableWithAllMeasurementsAttachments"
              :uploadCallback="onUploadProjectAttachment"
              v-on:dropzone-upload:reset="
                isTestReportAvailableWithAllMeasurementsAttachmentsUploadComplete = false
              "
              acceptedFileTypes="image/*"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.rcdTypeB300maAvailableInMeterAreaState">
      <template v-slot:header>
        {{ $t('rcdTypeB300maAvailableInMeterAreaState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('rcdTypeB300maAvailableInMeterAreaState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.rcdTypeB300maAvailableInMeterAreaState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.rcdTypeB300maAvailableInMeterAreaNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
        <div class="flex-row">
          <div class="flex-column name">{{ $t('image_upload') }}</div>
          <div class="flex-row">
            <div class="flex-column select">
              <div>
                {{ $t('templateAttachments') }}:
                <AttachmentGallery
                  :attachments="getReportTemplateImages[84].templateAttachments"
                  :deletable="false"
                  :sortByName="false"
                  :showFilename="false"
                ></AttachmentGallery>
              </div>
            </div>
          </div>

          <div class="flex-column select">
            <AttachmentGallery
              :attachments="project.rcdTypeB300maAvailableInMeterAreaAttachments"
              attachmentType="rcdTypeB300maAvailableInMeterAreaAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
              :sortByName="false"
            ></AttachmentGallery>
            <DropzoneUpload
              class="mt-2"
              :complete="isRcdTypeB300maAvailableInMeterAreaAttachmentsUploadComplete"
              :parentId="project.id"
              parentType="rcdTypeB300maAvailableInMeterAreaAttachments"
              :uploadCallback="onUploadProjectAttachment"
              v-on:dropzone-upload:reset="
                isRcdTypeB300maAvailableInMeterAreaAttachmentsUploadComplete = false
              "
              acceptedFileTypes="image/*"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.stringPlanFiledInMeterCabinetState">
      <template v-slot:header>
        {{ $t('stringPlanFiledInMeterCabinetState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('stringPlanFiledInMeterCabinetState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.stringPlanFiledInMeterCabinetState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.stringPlanFiledInMeterCabinetNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.safetyDistancesMaintainedState">
      <template v-slot:header>
        {{ $t('safetyDistancesMaintainedState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('safetyDistancesMaintainedState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.safetyDistancesMaintainedState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.safetyDistancesMaintainedNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.correctLocationForSystemState">
      <template v-slot:header>
        {{ $t('correctLocationForSystemState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('correctLocationForSystemState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.correctLocationForSystemState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.correctLocationForSystemNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.correctLocationForWallboxState">
      <template v-slot:header>
        {{ $t('correctLocationForWallboxState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('correctLocationForWallboxState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.correctLocationForWallboxState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.correctLocationForWallboxNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.customerRequestsDocumentedState">
      <template v-slot:header>
        {{ $t('customerRequestsDocumentedState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('customerRequestsDocumentedState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.customerRequestsDocumentedState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.customerRequestsDocumentedNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.slsInstalledState">
      <template v-slot:header>
        {{ $t('slsInstalledState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('slsInstalledState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.slsInstalledState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.slsInstalledNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.componentsDamagedState">
      <template v-slot:header>
        {{ $t('componentsDamagedState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('componentsDamagedState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.componentsDamagedState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.componentsDamagedNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
      </div>
    </AccordionCard>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AccordionCard from '@/components/AccordionCard';
import SelectButton from 'primevue/selectbutton';
import Textarea from 'primevue/textarea';
import DropzoneUpload from '@/components/DropzoneUpload';
import AttachmentGallery from '@/components/AttachmentGallery';

export default {
  name: 'SubstructureQualityReportAccordions',
  components: {
    AccordionCard,
    SelectButton,
    Textarea,
    DropzoneUpload,
    AttachmentGallery,
  },
  data() {
    return {
      /** track Upload states for clearing dropzone preview */
      isPvSystemLabeledInOrAtDistributionBoardAttachmentsUploadComplete: false,
      isTestReportAvailableWithAllMeasurementsAttachmentsUploadComplete: false,
      isRcdTypeB300maAvailableInMeterAreaAttachmentsUploadComplete: false,
    };
  },
  computed: { ...mapGetters(['getEnumValues', 'getReportTemplateImages']) },
  props: {
    project: { type: Object },
    cards: { type: Object },
  },
  methods: {
    ...mapActions(['uploadProjectAttachment', 'deleteProjectAttachment']),

    async onUploadProjectAttachment(payload) {
      await this.uploadProjectAttachment(payload);
    },

    async onDeleteProjectAttachment(event) {
      await this.deleteProjectAttachment({
        projectId: this.project.id,
        attachmentId: event.attachmentId,
        attachmentType: event.attachmentType,
      });
    },
  },
  async mounted() {
    // after upload is complete emit event to clear dropzone preview
    this.$store.subscribeAction({
      after: (action, state) => {
        if (action.type === 'uploadProjectAttachment') {
          this.isPvSystemLabeledInOrAtDistributionBoardAttachmentsUploadComplete = true;
          this.isTestReportAvailableWithAllMeasurementsAttachmentsUploadComplete = true;
          this.isRcdTypeB300maAvailableInMeterAreaAttachmentsUploadComplete = true;
        }
      },
    });
  },
};
</script>

<style lang="scss" scoped></style>
